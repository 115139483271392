import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { CLAIMS_KEY } from 'src/app/_helpers/routes';
import { Commons } from '../_helpers/commons';
import jwt_decode from 'jwt-decode';


@Injectable()
export class AuthGuard  {
    constructor(private router: Router, private common: Commons) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        const encodedToken = localStorage?.getItem('token') ?? "";
        const refreshToken = localStorage?.getItem('refresh_token') ?? "";

        try {
            if (!encodedToken || encodedToken.length === 0)
            {
                localStorage.removeItem("token");
                this.router.navigate(['/login'], { queryParams: { returnUrl: state.url } });
                return false;
            }

            this.common.token = encodedToken;
            this.common.refresh_token=refreshToken;
            const decodedToken: any = jwt_decode(encodedToken);
            this.common.roleId=decodedToken[CLAIMS_KEY.ROLE];
            this.common.validite = decodedToken.exp;
            const isActiveToken = decodedToken.exp > (Date.now() / 1000);

            if (!isActiveToken)
            {
                //localStorage.removeItem("token");
                this.router.navigate(['/login'], { queryParams: { returnUrl: state.url } });
                return false;
            }
            return true; // logged in so return true

         } catch(err) {
            localStorage.removeItem("token");
            this.router.navigate(['/error'], { queryParams: { message:err.message,returnUrl: "/login" }});
            return false;
        }
    }
}