import { Injectable } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { TranslationService } from "../_services/translation.service";


@Injectable({
  providedIn: 'root'
})
export class Commons {

  private _token: string="";
  private _validite: string="";
  private _refreshtoken:string="";
  private _language: string="";
  private _roleId:string="";

  constructor(private translateService: TranslateService, private smagTranslationService: TranslationService) { }

  public initTranslationService(): void {
    this.translateService.addLangs(['en-GB', 'fr-FR', 'pt-PT', 'es-ES', 'nl-NL', 'nl-BE', 'fr-BE', 'fr', 'es', 'en', 'nl', 'pt']);
    const browserLang = this.translateService.getBrowserLang();
    this.translateService.setDefaultLang(browserLang ? browserLang : 'fr-FR');
    this.language=this.translateService.getDefaultLang();
    this.translateService.use(this.translateService.getDefaultLang());

    this.smagTranslationService.getUserLanguage().then((language: any) => {
      this.translateService.setDefaultLang(language.Code);
      this.language=language.Code;
      this.translateService.use(language.Code);
    });

  }
  public get language(): string {
    return this._language;
  }
  public set language(value: string) {
    this._language = value;
  }

  public get token(): string {
    return this._token;
  }
  public set token(value: string) {
    this._token = value;
  }

  public get roleId(): string {
    return this._roleId;
  }
  public set roleId(value: string) {
    this._roleId = value;
  }

  public get validite(): string {
    return this._validite;
  }
  public set validite(value: string) {
    this._validite = value;
  }

  public get refresh_token(): string {
    return this._refreshtoken;
  }
  public set refresh_token(value: string) {
    this._refreshtoken = value;
  }
}