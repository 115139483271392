import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Identity } from '../_models/authentification';
import { Commons } from '../_helpers/commons';

@Injectable({
  providedIn: 'root'
})
export class IdentityService {

  constructor(private http: HttpClient, private common:Commons) { }

  getApplications() {
    const url = `${environment.API_URLS.smag_authentication_operational}api/subscriptions/current_user`;
    return this.http.get<Identity[]>(url);
  }

  generate(appID:string, refreshtoken:string){
    const url = `${environment.API_URLS.smag_authentication_operational}api/oauth2/refresh_token`;
    return this.http.post<any>(url, {ApplicationId:appID, RefreshToken:refreshtoken, RoleId:this.common.roleId});
  }
}
